@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body, html {
      @apply font-barlow   m-0 p-0 text-black scroll-smooth
  }
  h1 {
    @apply text-[2.5rem] md:text-[3rem] font-bold
}
  h2 {
    @apply text-[2.2rem] text-black
}
p{
    @apply text-[1.2rem] text-black/70 font-mooli 
}
table {
  @apply w-full
}
  }